.container {
    display: flex;
    justify-content: center;
    padding: 10px 10%;
}

.idi-logo {
    width: 100%;
    display: flex;
    background-color: #30383B;
}

@media screen and (min-width: 700px) {
    .idi-logo {
        padding: 20px 0;
        background-color: transparent;
        justify-content: center;
    }
}

.idi-logo img {
    height: 40px;
    padding: 10px;
    padding-left: 5px;
}

@media screen and (min-width: 700px) {
    .idi-logo img {
        height: 110px;
    }
}

.title {
    font-weight: 300;
    font-size: 2em;
    margin-bottom: 20px;
}